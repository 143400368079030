import {
    Component,
    ElementRef,
    Input,
    OnInit
} from '@angular/core';
import {
    FloorPlan3DModel,
    IDataFloor
} from '@app/core';

@Component({
    selector: 'plan-view-3d',
    templateUrl: 'planView3D.component.html',
    styleUrls: ['./planView3D.component.scss'],
    providers: [
        FloorPlan3DModel
    ],
    host: {
        class: 'plan-view-3d',
    }
})

export class PlanView3DComponent implements OnInit {

    @Input() data: IDataFloor;

    constructor(
        private elementRef: ElementRef,
        public floorPlan3DModel: FloorPlan3DModel
    ) {
    }

    public ngOnInit(): void {
        this.initViewer();
    }

    private initViewer(): void {
        const wrapper = this.elementRef.nativeElement as HTMLElement;
        this.floorPlan3DModel.init(wrapper, this.data);
        this.floorPlan3DModel.createContent();
        this.floorPlan3DModel.render();
        window.addEventListener('resize', () => {
            this.floorPlan3DModel.resize();
        }, false);

    }
}
