<div @fadeInContentByOpacity
     class="plan-context-menu-content app-border"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'"
     *ngIf="isMenuVisible">
     <div *ngIf="!menu.isRulerAvailable"
          class="menu-item"
          (click)="measureDistance($event)">
          <svg xmlns="http://www.w3.org/2000/svg"
               class="menu-item-icon"
               width="16"
               height="16"
               viewBox="0 0 16 16"
               fill="none">
               <path d="M10.9218 0L0 10.9212L5.07819 15.9994L16 5.07883L10.9218 0ZM10.9218 1.83395L14.1667 5.07754L5.07819 14.1667L1.83395 10.9218L2.5794 10.1764L3.96969 11.5667L4.89699 10.66L3.48606 9.24907L4.25214 8.50363L4.81573 9.06787L5.72304 8.14122L5.15879 7.59697L5.92423 6.83089L7.31453 8.22183L8.22183 7.31453L6.83089 5.92423L7.59697 5.15879L8.14122 5.72304L9.06787 4.81638L8.50363 4.25149L9.24972 3.48606L10.66 4.89699L11.5667 3.96969L10.1764 2.5794L10.9218 1.83395Z"
                    fill="#6E85A1" />
          </svg>
          <span class="menu-item-caption">
               {{res.measure}}
          </span>
     </div>
     <div class="menu-item"
          *ngIf="menu.isRulerAvailable"
          (click)="deleteDistance()">
          {{res.stopMeasuring}}
     </div>
     <div class="menu-item add-device"
          (click)="addDevice()">
          {{res.addDevice}}
     </div>
     <div class="menu-item add-area"
          (click)="addArea()">
          {{res.addArea}}
     </div>
</div>