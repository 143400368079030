import {
    Component,
    Input
} from '@angular/core';
import {
    AreaPlanModel,
    MapEntityTypeEnum,
    FloorPlanModel
} from '@app/core';

@Component({
    selector: '[area-map]',
    templateUrl: 'areaMap.component.html',
    styleUrls: ['./areaMap.component.scss'],
    host: {
        class: 'area-map'
    }
})
export class AreaMapComponent {

    @Input() area: AreaPlanModel;

    public get isVisible(): boolean {
        return this.area?.isVisible;
    }

    public get isTrafficLine(): boolean {
        return this.area?.type === MapEntityTypeEnum.trafficLine;
    }

    public get isBaseArea(): boolean {
        return this.area?.type === MapEntityTypeEnum.desk ||
            this.area?.type === MapEntityTypeEnum.meetingRoom ||
            this.area?.type === MapEntityTypeEnum.reception;
    }

    public get isAreasAnalyticsVisible(): boolean {
        return this.planModel.options.isAreasAnalyticsVisible;
    }

    public get isAreasTuningVisible(): boolean {
        return this.planModel.options.isAreasTuningVisible;
    }

    public get isTrafficLineAnalyticsVisible(): boolean {
        return this.planModel.options.isTrafficLinesAnalyticsVisible;
    }

    public get isTrafficLineTuningVisible(): boolean {
        return this.planModel.options.isTrafficLinesTuningVisible;
    }

    constructor(
        public planModel: FloorPlanModel
    ) {

    }

}
