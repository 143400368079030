<svg:g [ngClass]="area.type + ' ' + areaOccupancy"
    [attr.transform]="transformArea"
    [class.selected]="selected"
    [class.selected-as-relative]="selectedAsRelative"
    [class.has-favorite-tag]="hasFavoriteTag"
    [class.invalid]="!isValid">
    <polygon [attr.points]="area.relativePoints">
    </polygon>
    <g>
        <foreignObject width="16"
            height="16"
            [attr.transform]="ralativeTransform"
            x="0"
            y="0">
            <people-count-map [item]="area"
                *ngIf="isPeopleCountVisible"></people-count-map>
            <booking-status [item]="area"
                *ngIf="isBookingStatusVisible"></booking-status>
        </foreignObject>
    </g>
</svg:g>