import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AreaPlanBaseModel,
    AreaTrafficLineModel,
    DevicePlanModel,
    FloorPlanModel,
    PermissionsProvider
} from '@app/core';
import { FloorPlanRes } from '@app/core';
import {
    SubscriptionNotifier
} from '@app/shared';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: '[tuning-traffic-line-map]',
    templateUrl: 'tuningTrafficLine.component.html',
    styleUrls: ['./tuningTrafficLine.component.scss'],
    host: {
        class: 'tuning-traffic-line-map'
    }
})
export class TuningTrafficLineComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input() model: AreaTrafficLineModel;

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @HostListener('mousedown', ['$event'])
    componentMouseDown(event: MouseEvent) {
        this.mouseDown(event);
    }

    @HostListener('click', ['$event'])
    componentClick(event: MouseEvent) {
        this.openTooltip(event);
    }

    public get trafficAreaDepthPoints(): string {
        return this.model.trafficAreaDepthPoints;
    }

    public get trafficAreaPoints(): string {
        return this.model.trafficAreaPoints;
    }

    public get borderPoints(): string {
        return this.model.borderPoints;
    }

    public get trafficArrowInPoints(): string {
        return this.model.trafficArrowInPoints;
    }

    public get trafficArrowOutPoints(): string {
        return this.model.trafficArrowOutPoints;
    }

    public get transformArea(): string {
        return this.model.transformArea;
    }

    private pSelectedAsRelative: boolean;
    public get selectedAsRelative(): boolean {
        return this.pSelectedAsRelative;
    }

    public get selected(): boolean {
        return this.model.id === this.planModel.selectedObject?.id;
    }

    private pSelectedByGroup: boolean;
    public get selectedByGroup(): boolean {
        return this.pSelectedByGroup;
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
        private permissionsProvider: PermissionsProvider,
        public planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {

        this.planModel
            .floorEvents
            .onDeviceClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onAreaClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.selectByGroup();
            });
        this.planModel
            .floorPlanDevicesLayerModel
            .onLayerContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.selectAsRelative();
        this.changeDetectorRef.detectChanges();
    }

    private selectArea(event: MouseEvent): void {
        event.stopPropagation();
        this.planModel.floorPlanAreasLayerModel.selectArea(this.model.toContract());
    }

    private mouseDown(e: MouseEvent): void {
        if (this.permissionsProvider.cpmsEditArea && this.planModel.options.isDragAndDropForObject && this.planModel.selectedObject?.id === this.model.id) {
            const mirror = this.planModel.floorPlanAreasLayerModel.drag.draggableMirrorElement.nativeElement as HTMLElement;
            const area = this.planModel.selectedObject as AreaPlanBaseModel;

            const width = this.model.width * this.planModel.planViewBox.initializingScaleX;
            const height = this.model.length * this.planModel.planViewBox.initializingScaleY;
            this.planModel.transformPoint1.x = 0;
            this.planModel.transformPoint1.y = 0;
            this.planModel.transformPoint2.x = width;
            this.planModel.transformPoint2.y = height;
            const matrix = this.planModel.svgContainer.getScreenCTM().inverse();
            const p1 = this.planModel.transformPoint1.matrixTransform(matrix);
            const p2 = this.planModel.transformPoint2.matrixTransform(matrix);

            mirror.style.width = width * width / (p2.x - p1.x) + 'px';            
            mirror.style.height = height * height / (p2.y - p1.y) + 'px';

            mirror.style.transform = 'translateX(-50%) translateY(0%) rotate(-' + area.rotation + 'deg)';
            mirror.style.transformOrigin = 'center 0px';
            this.planModel.floorPlanAreasLayerModel.drag.startDrag(e, (this.elementRef.nativeElement as HTMLElement).querySelector('.traffic'), this.model.type);
        } else {
            this.selectArea(e);
        }
    }

    private selectAsRelative(): void {
        this.pSelectedAsRelative = (this.planModel.selectedObject as DevicePlanModel)?.linkedAreas?.some(t => t === this.model.id) || false;
    }

    private selectByGroup(): void {        
        this.pSelectedByGroup = (this.planModel.groupOfSelectedObjects as Array<AreaPlanBaseModel>)?.some(t => t.id === this.model.id);
    }

    private openTooltip(event: MouseEvent): void {
        this.planModel.floorPlanAreasLayerModel.setTooltipData(event.target as HTMLElement, this.model);
        this.planModel
            .onPlanDetectChanges
            .emit();
    }

}
