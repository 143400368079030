import {
    ChangeDetectorRef,
    ElementRef,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN
} from '@appConstants';
import {
    Component,
    Input
} from '@angular/core';
import {
    FloorPlanRes,
    FloorPlanModel,
    GlobalRes,
    AreaTrafficLineModel,
    FLOOR_PLAN_STORAGE_TOKEN,
    IFloorPlanStorage,
    TrafficLineStatsModel
} from '@app/core';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { BaseTooltipComponent } from '../baseTooltip';
import { Inject } from '@angular/core';
import {
    OccupancyStatusEnum
} from '@app/core/analytics/enums';

@Component({
    selector: 'analytics-area-traffic-line-tooltip',
    templateUrl: 'analyticsAreaTrafficLineTooltip.component.html',
    styleUrls: ['./analyticsAreaTrafficLineTooltip.component.scss'],
    host: {
        class: 'analytics-area-traffic-line-tooltip',
    },
    providers: [
        TrafficLineStatsModel
    ],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN],
})
export class AnalyticsAreaTrafficLineTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private baseFontSize = 1.154;
    private baseLength = 2;
    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    private chart: google.visualization.LineChart;

    private get areaTrafficLine(): AreaTrafficLineModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaTrafficLineModel;
    }

    public isPending: boolean;
    public ocupancyStatus = OccupancyStatusEnum;

    @Input() planModel: FloorPlanModel;

    public get fontSizeIn(): string {
        return this.fontSize(this.trafficLineStatsModel.peopleInNumber || 0);
    }

    public get fontSizeOut(): string {
        return this.fontSize(this.trafficLineStatsModel.peopleOutNumber || 0);
    }

    constructor(
        elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private popupsDialogService: PopupsDialogService,
        @Inject(FLOOR_PLAN_STORAGE_TOKEN) private readonly floorPlanStorage: IFloorPlanStorage,
        private errorHandler: ErrorHandlerService,
        public trafficLineStatsModel: TrafficLineStatsModel,
        public res: FloorPlanRes,
        public globalRes: GlobalRes
    ) {
        super(elementRef, res);
        this.isPending = true;
    }

    public ngOnInit(): void {
        this.onInit();
        this.initComponent();
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    protected getTooltipData(): AreaTrafficLineModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaTrafficLineModel;
    }

    private initComponent(): void {
        this.loadData();
        this.planModel
            .floorPlanAreasLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    private loadData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector);
        this.trafficLineStatsModel
            .load(this.areaTrafficLine.id, this.floorPlanStorage.getTimezone())
            .then(() => {
                this.initChart();
            })
            .catch((error) => {
                this.isPending = false;
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

    private initChart(): void {
        google.charts.load('current', { packages: ['line', 'corechart'] });
        google.charts.setOnLoadCallback(() => {
            this.chart = new google.visualization.LineChart(this.elementRef.nativeElement.querySelector('#peopleTrafficTrendChart'));
            this.popupsDialogService.breackRelativeLoading(this.blockSelector);
            this.isPending = false;
            this.trafficLineStatsModel.drawTrends(this.chart);
            this.changeDetectorRef.detectChanges();
        });
    }

    private fontSize(value: number): string {
        return (this.baseFontSize - Math.max(value.toString().length - this.baseLength, 0) * 0.3) + 'em';
    }

}
