import {
    AfterViewInit,
    Component,
    Input
} from '@angular/core';
import {
    DetectionObjectPlanModel
} from '@app/core';

@Component({
    selector: '[detection-table]',
    templateUrl: 'detectionTable.component.html',
    styleUrls: ['./detectionTable.component.scss'],
    host: {
        class: 'detection-table'
    }
})
export class DetectionTableComponent implements AfterViewInit {

    @Input() object: DetectionObjectPlanModel;

    constructor(
    ) {
    }

    public ngAfterViewInit(): void {
    }

}

