import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FloorPlanModel
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';
import { PositionContract } from '@app/storage';

@Component({
    selector: 'device-mirror-element',
    templateUrl: 'deviceMirrorElement.component.html',
    styleUrls: ['./deviceMirrorElement.component.scss'],
    host: {
        class: 'device-mirror-element',
    }
})
export class DeviceMirrorElementComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public get src(): string {
        return '/assets/images/sensor.png';
    }

    public get position(): PositionContract {
        const rect = (this.element.nativeElement as HTMLElement).getBoundingClientRect();
        const x = rect.x + rect.width / 2;
        const y = rect.y + rect.height / 2;
        return this.planModel.convertScreenPositionToFloorPositionInMeters(x, y);
    }

    constructor(
        private planModel: FloorPlanModel,
        private element: ElementRef
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.planModel.floorPlanDevicesLayerModel.drag.init(this.element);
    }

}
