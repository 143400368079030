import {
    AfterViewInit,
    Component,
    ElementRef,
    Input
} from '@angular/core';
import {
    DetectionObjectPlanModel
} from '@app/core';

@Component({
    selector: '[detection-laptop]',
    templateUrl: 'detectionLaptop.component.html',
    styleUrls: ['./detectionLaptop.component.scss'],
    host: {
        class: 'detection-laptop'
    }
})
export class DetectionLaptopComponent implements AfterViewInit {

    @Input() object: DetectionObjectPlanModel;

    constructor(
        private elementRef: ElementRef
    ) {

    }

    public ngAfterViewInit(): void {
        this.setDimensions();
    }

    public onImageLoad(): void {
        this.setDimensions();
    }

    private setDimensions(): void {
    }
}

