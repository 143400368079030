import {
    AfterViewInit,
    Component,
    Input
} from '@angular/core';
import {
    CommissioningObjectPlanModel,
    ObjectDetectionTypeEnum
} from '@app/core';

@Component({
    selector: '[commissioning-object]',
    templateUrl: 'commissioningObject.component.html',
    styleUrls: ['./commissioningObject.component.scss'],
    host: {
        class: 'commissioning-object'
    }
})
export class CommissioningObjectComponent implements AfterViewInit {

    @Input() object: CommissioningObjectPlanModel;

    public get objectType(): string {
        switch (this.object.type) {
            case ObjectDetectionTypeEnum.chair:
                return 'chair';
            case ObjectDetectionTypeEnum.laptop:
                return 'laptop';
            case ObjectDetectionTypeEnum.table:
                return 'table';
        }
    }

    public get text(): string {
        return this.object.type + ' ' + this.object?.confidence;
    }

    constructor(
    ) {

    }

    public ngAfterViewInit(): void {
    }
}