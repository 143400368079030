<svg:g [ngClass]="model.type"
    [class.invalid]="!isValid"
    [class.selected]="selected"
    [class.selected-as-relative]="selectedAsRelative"
    [attr.transform]="transformArea">
    <polygon class="traffic-depth"
        [attr.fill]="isValid ? 'url(#hatch1)' : 'url(#hatch1Invalid)'"
        [attr.points]="trafficAreaDepthPoints">
    </polygon>
    <polygon class="traffic"
        [attr.points]="trafficAreaPoints">
    </polygon>
    <polygon class="traffic-in "
        [attr.points]="trafficArrowInPoints">
    </polygon>
    <polygon class="traffic-out "
        [attr.points]="trafficArrowOutPoints">
    </polygon>
    <polygon class="border"
        [attr.points]="borderPoints">
    </polygon>
    <defs>
        <pattern id="hatch1"
            patternUnits="userSpaceOnUse"
            width="4"
            height="4">
            <path d="M -1 1 l 2 -2 
            M -1 3 l 4 -4 
            M 0 4 l 4 -4 
            M 1 5 l 4 -4 
            M 3 5 l 2 -2"
                style="stroke:#6e85a122; stroke-width:4;" />
            <path d="M -1 1 l 2 -2 
            M -1 3 l 4 -4 
            M 0 4 l 4 -4 
            M 1 5 l 4 -4 
            M 3 5 l 2 -2"
                style="stroke:#6e85a1; stroke-width:0.25;" />
        </pattern>
        <pattern id="hatch1Invalid"
            patternUnits="userSpaceOnUse"
            width="4"
            height="4">
            <path d="M -1 1 l 2 -2 
        M -1 3 l 4 -4 
        M 0 4 l 4 -4 
        M 1 5 l 4 -4 
        M 3 5 l 2 -2"
                style="stroke:#6e85a122; stroke-width:4;" />
            <path d="M -1 1 l 2 -2 
        M -1 3 l 4 -4 
        M 0 4 l 4 -4 
        M 1 5 l 4 -4 
        M 3 5 l 2 -2"
                style="stroke:#f8051a; stroke-width:0.25;" />
        </pattern>
    </defs>
</svg:g>