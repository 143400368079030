import {
    Component
} from '@angular/core';
import {
    FloorPlanRulerModel,
    FloorPlanModel,
    IScaleFactor,
    GlobalRes
} from '@app/core';
import { PositionContract } from '@app/storage';
import { FADE_IN_CONTENT_BY_OPACITY } from '@appConstants';

@Component({
    selector: '[plan-ruler]',
    templateUrl: 'planRuler.component.html',
    styleUrls: ['./planRuler.component.scss'],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY
    ]
})
export class PlanRulerComponent {

    public get ruler(): FloorPlanRulerModel {
        return this.planModel.contextMenu.ruler;
    }

    public get scale(): IScaleFactor {
        return this.planModel.contextMenu.scale;
    }

    public get isRulerAvailable(): boolean {
        return this.planModel.contextMenu.isRulerAvailable;
    }

    private pTotalDistance: string = '';
    public get totalDistance(): string {
        const l = this.ruler.totalDistance;
        this.pTotalDistance = this.globalRes.total + ': ' + this.formatDistance(l);
        return this.pTotalDistance;
    }

    public get rulerValueWidth(): number {
        return this.calculateTitleLength(this.pTotalDistance);
    }

    public get points(): Array<PositionContract> {
        return this.ruler.points.filter((t, index) => index > 0);
    }

    constructor(
        public globalRes: GlobalRes,
        public planModel: FloorPlanModel
    ) {

    }

    public segmentDistance(index: number): string {
        const l = this.ruler.calculateSegmentDistanceByIndex(index);
        return this.formatDistance(l);
    }

    public rulerSegmentValueWidth(index: number): number {
        const l = this.segmentDistance(index);
        return this.calculateTitleLength(l);
    }

    public isLastSegment(index: number): boolean {
        return this.points.length == index + 1;
    }

    private formatDistance(l: number): string {
        let distance = '';
        if (l < 1) {
            distance = Math.round( 100 * 100 * l) / 100 + ' cm';
        } else {
            const inMeter = Math.floor(l);
            distance = inMeter + ' m ' + Math.round( 100 * 100 * (l - inMeter)) /100 + ' cm';
            }
        return distance;
    }

    private calculateTitleLength(title: string): number {
        return 6 * ((title.length || 3.5) + 2);
    }

}
