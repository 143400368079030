import {
    Component
} from '@angular/core';
import {
    FloorPlanRes,
    MapEntityTypeEnum,
    FloorPlanModel,
    AreaPlanBaseModel
} from '@app/core';
import { FADE_IN_CONTENT_BY_OPACITY } from '@appConstants';

@Component({
    selector: 'map-tooltip',
    templateUrl: 'mapTooltip.component.html',
    styleUrls: ['./mapTooltip.component.scss'],
    host: {
        class: 'map-tooltip',
    },
    animations: [
        FADE_IN_CONTENT_BY_OPACITY
    ]
})
export class MapTooltipComponent {

    private get areaData(): AreaPlanBaseModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData;
    }

    private get isAreasAnalyticsTooltipVisible(): boolean {
        return this.planModel.options.isAreasAnalyticsTooltipVisible;
    }

    public get isVisibleReceptionTooltip(): boolean {
        return this.areaData?.type?.toLowerCase() === MapEntityTypeEnum.reception.toLowerCase() && this.isAreasAnalyticsTooltipVisible;
    }

    public get isVisibleMeetingTooltip(): boolean {
        return this.areaData?.type?.toLowerCase() === MapEntityTypeEnum.meetingRoom.toLowerCase() && this.isAreasAnalyticsTooltipVisible;
    }

    public get isVisibleDeskTooltip(): boolean {
        return this.areaData?.type?.toLowerCase() === MapEntityTypeEnum.desk.toLowerCase() && this.isAreasAnalyticsTooltipVisible;
    }

    public get isVisibleAnalyticsTraficLineTooltip(): boolean {
        return this.areaData?.type?.toLowerCase() === MapEntityTypeEnum.trafficLine.toLowerCase() && this.isAreasAnalyticsTooltipVisible;
    }

    public get isVisibleTuningsTraficLineTooltip(): boolean {
        return this.areaData?.type?.toLowerCase() === MapEntityTypeEnum.trafficLine.toLowerCase() && this.planModel.options.isTrafficLinesTuningTooltipVisible;
    }

    public get isDeviceAnalyticsTooltipVisible(): boolean {
        return this.planModel.floorPlanDevicesLayerModel.isTooltipVisible && this.planModel.options.isDeviceAnalyticsTooltipVisible;
    }

    public get isDeviceMasterTooltipVisible(): boolean {
        return this.planModel.floorPlanDevicesLayerModel.isTooltipVisible && this.planModel.options.isDeviceMasterTooltipVisible;
    }

    public get isTooltipVisible(): boolean {
        return this.planModel?.isTooltipVisible;
    }

    constructor(
        private planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

}
