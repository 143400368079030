import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FloorPlanRes,
    AreaPlanModel,
    MapEntityTypeEnum,
    FloorPlanModel,
    GlobalRes,
    FLOOR_PLAN_STORAGE_TOKEN,
    IFloorPlanStorage,
    ReceptionStatsModel
} from '@app/core';
import {
    ErrorHandlerService,
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { ElementRef } from '@angular/core';
import { BaseTooltipComponent } from '../baseTooltip';
import {
    OccupancyStatusEnum
} from '@app/core/analytics/enums';

@Component({
    selector: 'area-reception-tooltip',
    templateUrl: 'areaReceptionTooltip.component.html',
    styleUrls: ['./areaReceptionTooltip.component.scss'],
    host: {
        class: 'area-reception-tooltip',
    },
    providers: [
        ReceptionStatsModel
    ],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN]
})
export class AreaReceptionTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    private chart: google.visualization.LineChart;

    public isPending: boolean;
    public ocupancyStatus = OccupancyStatusEnum;

    @Input() planModel: FloorPlanModel;

    public get data(): AreaPlanModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaPlanModel;
    }

    public get typeArea(): string {
        switch (this.data.type.toLowerCase()) {
            case MapEntityTypeEnum.reception.toLowerCase():
                return this.res.reception;
            default:
                return this.res.unknown;
        }
    }

    public get statusName(): string {

        switch (this.model.status) {
            case OccupancyStatusEnum.occupied:
                return this.res.occupied;
            case OccupancyStatusEnum.vacant:
                return this.res.vacant;
            default:
                return this.res.unknown;
        }
    }

    constructor(
        elementRef: ElementRef,
        res: FloorPlanRes,
        private changeDetectorRef: ChangeDetectorRef,
        private popupsDialogService: PopupsDialogService,
        private errorHandler: ErrorHandlerService,
        @Inject(FLOOR_PLAN_STORAGE_TOKEN) private readonly floorPlanStorage: IFloorPlanStorage,
        public model: ReceptionStatsModel,
        public globalRes: GlobalRes
    ) {
        super(elementRef, res);
        this.isPending = true;
    }

    public ngOnInit(): void {
        this.onInit();
        this.initComponent();
        this.model.setOccupancyStatus(this.data.peopleCount);
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public getTooltipData(): AreaPlanModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaPlanModel;
    }

    public mouseWheel(event: WheelEvent): void {
        event.stopPropagation();
    }

    private initComponent(): void {
        this.loadData();
        this.planModel
            .floorPlanAreasLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    private loadData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector);
        this.model
            .load(this.data.id, this.floorPlanStorage.getTimezone())
            .then(() => {
                this.initChart();
            })
            .catch((error) => {
                this.isPending = false;
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

    private initChart(): void {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(() => {
            this.chart = new google.visualization.LineChart(this.elementRef.nativeElement.querySelector('#areaOccupancyChart'));
            this.popupsDialogService.breackRelativeLoading(this.blockSelector);
            this.isPending = false;
            this.model.drawChart(this.chart);
            this.changeDetectorRef.detectChanges();
        });
    }


}
