import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ElementRef
} from '@angular/core';
import {
    CommissioningTargetPlanModel,
    DetectionAccuracyEnum,
    FloorPlanModel
} from '@app/core';
import {
    SubscriptionNotifier
} from '@app/shared';

@Component({
    selector: 'commissioning-target',
    templateUrl: 'commissioningTarget.component.html',
    styleUrls: ['./commissioningTarget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'commissioning-target',
        '[style.width]': 'width + "px"',
        '[style.height]': 'width + "px"'
    }
})
export class CommissioningTargetComponent implements OnInit, AfterViewInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() target: CommissioningTargetPlanModel;

    private pWidth: number;
    public get width(): number {
        return Math.min(Math.max(this.pWidth || 0, 2), 20);
    }

    public get src(): string {
        switch (this.target.status) {
            case DetectionAccuracyEnum.accurate:
                return '/assets/images/target.svg';
            case DetectionAccuracyEnum.approximate:
                return '/assets/images/presumedTarget.svg';
            default:
                return '/assets/images/target.svg';
        }
    }

    constructor(
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        public planModel: FloorPlanModel
    ) {

    }

    public ngOnInit(): void {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }


    public ngAfterViewInit(): void {
        this.setDimensions();
        this.changeDetectorRef.detectChanges();
        this.planModel
            .onZoom
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.setDimensions();
                this.changeDetectorRef.detectChanges();
            });
    }

    public onImageLoad(): void {
        this.setDimensions();
    }

    // private setDimensions(): void {
    //     this.pWidth = (this.elementRef.nativeElement as HTMLElement).clientHeight;
    // }

    private setDimensions(): void {
        const scale = this.planModel.planViewBox.getViewBoxScale();
        const scaleX = this.planModel.planViewBox.initializingScaleX / scale.scaleX;
        const pWidth = (this.elementRef.nativeElement as HTMLElement).clientHeight
        this.pWidth = 20 * scaleX;
    }

}
