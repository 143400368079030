import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy
} from '@angular/core';
import {
    AreaPlanBaseModel,
    AreaPlanModel,
    DevicePlanModel,
    FloorPlanModel,
    PermissionsProvider
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';

@Component({
    selector: '[tuning-area]',
    templateUrl: 'tuningArea.component.html',
    styleUrls: ['./tuningArea.component.scss'],
    host: {
        class: 'tuning-area'
    }
})
export class TuningAreaComponent implements OnDestroy, AfterViewInit {

    @Input() area: AreaPlanModel;

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public get isValid(): boolean {
        return this.area.valid;
    }

    @HostListener('mousedown', ['$event'])
    componentMouseDown(event: MouseEvent) {
        this.mouseDown(event);
    }

    public get transformArea(): string {
        return this.area.transformArea;
    }

    private pSelectedAsRelative: boolean;
    public get selectedAsRelative(): boolean {
        return this.pSelectedAsRelative;
    }

    public get selected(): boolean {
        return this.area.id === this.planModel.selectedObject?.id;
    }

    private pSelectedByGroup: boolean;
    public get selectedByGroup(): boolean {
        return this.pSelectedByGroup;
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
        private permissionsProvider: PermissionsProvider,
        public planModel: FloorPlanModel
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngAfterViewInit(): void {

        this.planModel
            .floorEvents
            .onDeviceClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onAreaClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.selectByGroup();
            });
        this.planModel
            .floorPlanDevicesLayerModel
            .onLayerContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
            });
        this.selectAsRelative();
        this.changeDetectorRef.detectChanges();
    }

    private selectArea(event: MouseEvent): void {
        event.stopPropagation();
        this.planModel.floorPlanAreasLayerModel.selectArea(this.area.toContract());
    }

    private mouseDown(e: MouseEvent): void {
        if (this.permissionsProvider.cpmsEditArea && this.planModel.options.isDragAndDropForObject && this.planModel.selectedObject?.id === this.area.id) {
            const mirror = this.planModel.floorPlanAreasLayerModel.drag.draggableMirrorElement.nativeElement as HTMLElement;
            const el = (this.elementRef.nativeElement as HTMLElement);
            const rect = el.getBoundingClientRect();
            const area = this.planModel.selectedObject as AreaPlanBaseModel;
            const aspectRate = area.width / area.length;
            const angleRad = (area.rotation) * Math.PI / 180;
            const height = rect.width / (aspectRate * Math.abs(Math.cos(angleRad)) + Math.abs(Math.cos(Math.PI / 2 - angleRad)));
            const width = height * aspectRate;
            mirror.style.height = height + 'px';
            mirror.style.width = width + 'px';
            mirror.style.transform = 'translateX(-50%) translateY(-50%) rotate(-' + area.rotation + 'deg)';
            mirror.style.transformOrigin = 'inherit';
            this.planModel.floorPlanAreasLayerModel.drag.startDrag(e, this.elementRef.nativeElement, this.area.type);
        } else {
            this.selectArea(e);
        }
    }

    private selectAsRelative(): void {
        this.pSelectedAsRelative = (this.planModel.selectedObject as DevicePlanModel)?.linkedAreas?.some(t => t === this.area.id);
    }

    private selectByGroup(): void {        
        this.pSelectedByGroup = (this.planModel.groupOfSelectedObjects as Array<AreaPlanBaseModel>)?.some(t => t.id === this.area.id);
    }

}
