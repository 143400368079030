import {
    AfterViewInit,
    Component,
    ElementRef,
    Input
} from '@angular/core';
import {
    DetectionObjectPlanModel
} from '@app/core';

@Component({
    selector: '[detection-chair]',
    templateUrl: 'detectionChair.component.html',
    styleUrls: ['./detectionChair.component.scss'],
    host: {
        class: 'detection-chair'
    }
})
export class DetectionChairComponent implements AfterViewInit {

    @Input() object: DetectionObjectPlanModel;


    constructor(
        private elementRef: ElementRef
    ) {

    }

    public ngAfterViewInit(): void {
        this.setDimensions();
    }

    public onImageLoad(): void {
        this.setDimensions();
    }

    private setDimensions(): void {
    }
}

