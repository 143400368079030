import {
    Component,
    ElementRef,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    Inject
} from '@angular/core';
import {
    FloorPlanRes,
    DevicePlanModel,
    FloorPlanModel,
    FLOOR_PLAN_STORAGE_TOKEN,
    IFloorPlanStorage
} from '@app/core';
import {
    ErrorHandlerService,
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { AnalyticsEnviromentalDataContract } from '@app/storage';
import { BaseTooltipComponent } from '../baseTooltip';
import { GlobalRes } from '@app/core';
import {
    AnalyticsDeviceDataModel,
    TemperatureTrendsModel
} from '@app/core/floorPlan/models';

@Component({
    selector: 'device-analytics-tooltip',
    templateUrl: 'deviceAnalyticsTooltip.component.html',
    styleUrls: ['./deviceAnalyticsTooltip.component.scss'],
    host: {
        class: 'device-analytics-tooltip',
    },
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN],
    providers: [
        AnalyticsDeviceDataModel,
        TemperatureTrendsModel
    ]
})
export class DeviceAnalyticsTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() planModel: FloorPlanModel;

    public isPending: boolean;

    public get environmentalData(): AnalyticsEnviromentalDataContract {
        return this.model.data?.environmentalData;
    }

    public get sensorTooltipData(): DevicePlanModel {
        return this.planModel?.floorPlanDevicesLayerModel.tooltipData || {} as any;
    }

    public get lastTransmission(): Date {
        return this.sensorTooltipData?.lastTransmission ? new Date(this.sensorTooltipData.lastTransmission.toLocaleString('en-US', { timeZone: this.floorPlanStorage.getTimezone() })) : null;
    }

    constructor(
        elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private popupsDialogService: PopupsDialogService,
        private errorHandler: ErrorHandlerService,
        @Inject(FLOOR_PLAN_STORAGE_TOKEN) private readonly floorPlanStorage: IFloorPlanStorage,
        public temperatureTrendsModel: TemperatureTrendsModel,
        public model: AnalyticsDeviceDataModel,
        public res: FloorPlanRes,
        public globalRes: GlobalRes
    ) {
        super(elementRef, res);
        this.isPending = true;
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.onInit();
        this.loadData();
        this.loadChartData();
        this.planModel
            .floorPlanDevicesLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    protected getTooltipData(): DevicePlanModel {
        return this.planModel.floorPlanDevicesLayerModel.tooltipData;
    }

    private loadData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector);
        this.model
            .load(this.planModel.floorPlanDevicesLayerModel.tooltipData.id)
            .then(
                () => {
                    this.changeDetectorRef.detectChanges();
                    this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                })
            .catch((error) => {
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

    private loadChartData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector);
        this.temperatureTrendsModel
            .load(this.model.id, this.floorPlanStorage.getTimezone())
            .then(() => {
                this.initChart();
            })
            .catch((error) => {
                this.isPending = false;
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

    private initChart(): void {
        google.charts.load('current', { packages: ['line', 'corechart'] });
        google.charts.setOnLoadCallback(() => {
            const chart = new google.visualization.LineChart(this.elementRef.nativeElement.querySelector('#temperatureTrendChart'));
            this.popupsDialogService.breackRelativeLoading(this.blockSelector);
            this.isPending = false;
            this.temperatureTrendsModel.drawChart(chart);
            this.changeDetectorRef.detectChanges();
        });
    }

}
