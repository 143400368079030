<div class="map-tooltip"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <div class="app-border content">
          <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
               (click)="close()"
               class="action-block close fa-icon"></fa-icon>
          <div class="row element-name">
               <span class="tooltip-label">{{res.sensor}}</span>
               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'temperature'}"
                         class="fa-icon temperature"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{environmentalData?.temperature | number : '1.0-0' | doubledash}}">
                    {{environmentalData?.temperature | number : '1.0-0' | doubledash}}
                    <span *ngIf="environmentalData?.temperature">&#176;С</span>
               </span>

               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'pressure'}"
                         class="fa-icon pressure"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{environmentalData?.pressure | number : '1.0-0' | doubledash}}">
                    {{environmentalData?.pressure | number : '1.0-0' | doubledash}}
                    <span *ngIf="environmentalData?.pressure">hPA</span>
               </span>

               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'humidity'}"
                         class="fa-icon humidity"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{environmentalData?.humidity | number : '1.0-0' | doubledash}} %">
                    {{environmentalData?.humidity | number : '1.0-0' | doubledash}}
                    <span *ngIf="environmentalData?.humidity">%</span>
               </span>

          </div>
          <div class="row">
               <span class="tooltip-label">
                    {{res.name}}
               </span>
               <span class="value text-overflow"
                    title="{{sensorTooltipData.name}}">
                    {{sensorTooltipData.name}}
               </span>
          </div>
          <div class="row position">
               <span class="tooltip-label">
                    {{res.position}}
               </span>
               <span class="label-x">
                    x:
               </span>
               <span class="value x">
                    {{sensorTooltipData.position.posX}}
               </span>
               <span class="label-y">
                    y:
               </span>
               <span class="value y">
                    {{sensorTooltipData.position.posY}}
               </span>
          </div>
          <div class="row orientation">
               <span class="tooltip-label">
                    {{res.orientation}}
               </span>
               <div class="tooltip-icon">
                    <fa-icon [style.transform]="'rotate(' + (180 - sensorTooltipData.rotation) + 'deg)'"
                         [icon]="{prefix: 'fas',  iconName: 'long-arrow-alt-down'}"
                         class="fa-icon long-arrow-alt-down"></fa-icon>
               </div>
          </div>
          <div class="row">
               <span class="tooltip-label">
                    {{res.lastTransmission}}
               </span>
               <span class="value text-overflow"
                    title="{{sensorTooltipData.lastTransmission}}">
                    {{(lastTransmission | date : 'd MMM, y h:mm a') || '--'}}
               </span>
          </div>
          <div class="chart-area">
               <div class="title">{{res.temperatureTrendForLast30days}}</div>
               <div id="temperatureTrendChart"
                    [@fadeInContentByOpacityForHidden]="!temperatureTrendsModel.isDataAvailable ? 'hidden':'visible'"
                    class="chart-block"></div>
               <div class="no-data"
                    @fadeInContentByOpacity
                    *ngIf="!temperatureTrendsModel.isDataAvailable && !isPending">
                    <div>
                         {{globalRes.noDataView}}
                    </div>
               </div>
          </div>
     </div>
</div>