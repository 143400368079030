import { DecimalPipe } from '@angular/common';
import {
    Component,
    ElementRef,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import {
    FloorPlanRes,
    CpmsDeviceDataModel,
    DevicePlanModel,
    FloorPlanModel,
    IMapTooltip
} from '@app/core';
import { CpmsRoutesConstants } from '@app/modules/cpms/constants';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { EnvDeviceDataContract } from '@app/storage';
import { BaseTooltipComponent } from '../baseTooltip';

@Component({
    selector: 'device-master-tooltip',
    templateUrl: 'deviceMasterTooltip.component.html',
    styleUrls: ['./deviceMasterTooltip.component.scss'],
    host: {
        class: 'device-master-tooltip',
    },
    providers: [
        CpmsDeviceDataModel
    ]
})
export class DeviceMasterTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    private numberPipe: DecimalPipe = new DecimalPipe('en-US');

    @Input() planModel: FloorPlanModel;

    private get environmentalData(): EnvDeviceDataContract {
        return this.model.data;
    }

    public get temperatureC(): string {
        return this.environmentalData?.temperatureC ? this.numberPipe.transform(this.environmentalData.temperatureC, '1.0-0') : null;
    }

    public get temperatureF(): string {
        return this.environmentalData?.temperatureF ? this.numberPipe.transform(this.environmentalData.temperatureF, '1.0-0') : null;
    }

    public get pressure(): string {
        return this.environmentalData?.pressureInHpa ? this.numberPipe.transform(this.environmentalData.pressureInHpa, '1.0-0') + ' hPA' : null;
    }

    public get humidity(): string {
        return this.environmentalData?.humidityInPercent ? this.numberPipe.transform(this.environmentalData.humidityInPercent, '1.0-0') + ' %' : null;
    }

    public get lastReported(): string {
        const sec = this.environmentalData?.lastReportedInSec;
        if (sec || sec == 0) {
            if (sec <= 0) {
                return this.res.justNow;
            } else if (sec > 0 && sec < 60) {
                return sec + ' ' + this.res.secAgo;
            } else if (sec >= 60 && sec < 60 * 60) {
                const min = Math.floor(sec / 60);
                return min + ' ' + this.res.minAgo;
            } else if (sec >= 60 * 60 && sec < 60 * 60 * 24) {
                const hour = Math.floor(sec / (60 * 60));
                return hour + ' ' + this.res.hourAgo;
            } else if (sec >= 60 * 60 * 24 && sec < 60 * 60 * 24 * 30) {
                const hour = Math.floor(sec / (60 * 60 * 24));
                return hour + ' ' + this.res.dayAgo;
            } else if (sec >= 60 * 60 * 24 * 30) {
                const hour = Math.floor(sec / (60 * 60 * 24 * 30));
                return hour + ' ' + this.res.monthAgo;
            }
        }
        return null;
    }

    public get linked(): string {
        return this.sensorTooltipData.isLinked ? this.res.linked : this.res.notLinked;
    }

    public get sensorTooltipData(): DevicePlanModel {
        return this.planModel?.floorPlanDevicesLayerModel.tooltipData || {} as any;
    }

    public get y(): number {
        let y = 0;
        const mapTooltip = this.getTooltipData() as IMapTooltip;
        if (mapTooltip) {
            const rectTarget = mapTooltip.tooltipTarget.getBoundingClientRect();
            const rectContainer = this.planModel.floorContainer.getBoundingClientRect();
            y = rectTarget.y - rectContainer.y;

            const deltaOverflowY = this.clientHeight + y - rectContainer.height;

            if (deltaOverflowY > 0) {
                y -= deltaOverflowY + 3;
            }
        }
        return y;
    }

    public get x(): number {
        let x = 0;
        const mapTooltip = this.getTooltipData() as IMapTooltip;
        if (mapTooltip) {
            const rectTarget = mapTooltip.tooltipTarget.getBoundingClientRect();
            const rectContainer = this.planModel.floorContainer.getBoundingClientRect();
            x = rectTarget.x - rectContainer.x + rectTarget.width;

            const deltaOverflowX = this.clientWidth + x - rectContainer.width + rectTarget.width;

            if (deltaOverflowX > 0) {
                x = x - this.clientWidth - rectTarget.width;
            }
        }
        return x;
    }

    public get deviceManagerPath(): Array<any> {
        return [CpmsRoutesConstants.CPMS_DEVICE_MANAGER_ROUTE];
    }

    public get deviceManagerStateForPath(): any {
        return { selectedDeviceId: this.sensorTooltipData.id, selectedFloorId: this.sensorTooltipData.floorId };
    }

    constructor(
        elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private popupsDialogService: PopupsDialogService,
        private errorHandler: ErrorHandlerService,
        public res: FloorPlanRes,
        public model: CpmsDeviceDataModel
    ) {
        super(elementRef, res);
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.onInit();
        this.loadData();
        this.planModel
            .floorPlanDevicesLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    protected getTooltipData(): DevicePlanModel {
        return this.planModel.floorPlanDevicesLayerModel.tooltipData;
    }

    private loadData(): void {
        if (!this.planModel.isSuspended) {
            this.popupsDialogService.startRelativeLoading(this.blockSelector);
            this.model
                .load({
                    floorId: this.planModel.floor.id,
                    id: this.planModel.floorPlanDevicesLayerModel.tooltipData.id
                })
                .pipe(this.subscriptions.register())
                .subscribe({
                    next: () => {
                        this.changeDetectorRef.detectChanges();
                        this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                    },
                    error: (error) => {
                        this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                        this.errorHandler.handleError(error);
                    }
                });
        }
    }

}
