<svg:g *ngIf="isRulerAvailable"
      class="context-menu-ruler"
      [attr.transform]="'translate(' + ruler.startX +',' + ruler.startY + ')'">
      <circle class="start-point"
            cx="0"
            cy="0"
            [attr.stroke-width]="3 * scale.scaleX"
            [attr.r]="4 * scale.scaleX" />
      <ng-container *ngFor="let point of points; index as i">
            <line [attr.x1]="points[i-1]?.posX || 0"
                  class="ruler-line"
                  [attr.y1]="points[i-1]?.posY || 0"
                  [attr.x2]="point.posX"
                  [attr.y2]="point.posY"
                  [attr.stroke-width]="1 * scale.scaleX" />
            <g [attr.transform]="'translate(' + point.posX +',' + point.posY + ')'">
                  <ng-container *ngIf="!isLastSegment(i)">
                        <rect [attr.width]="rulerSegmentValueWidth(i) * scale.scaleX"
                              [attr.x]="-rulerSegmentValueWidth(i)/2 * scale.scaleX"
                              [attr.y]="-28  * scale.scaleY "
                              [attr.height]="18 * scale.scaleY"
                              [attr.stroke-width]="1 * scale.scaleX"
                              stroke="#8b8080"
                              style="fill:rgb(251, 251, 252);" />
                        <text x="0"
                              [attr.y]="-15 * scale.scaleY"
                              [attr.font-size]="11 * scale.scaleY"
                              text-anchor="middle"
                              fill="#8b8080">{{segmentDistance(i)}}</text>
                  </ng-container>
                  <ng-container *ngIf="isLastSegment(i)">
                        <rect [attr.width]="(rulerSegmentValueWidth(points.length) + 50) * scale.scaleX"
                              [attr.x]="-(rulerSegmentValueWidth(points.length) + 50)/2  * scale.scaleX"
                              [attr.y]="-46 * scale.scaleY "
                              [attr.height]="36 * scale.scaleY"
                              [attr.stroke-width]="1 * scale.scaleX"
                              stroke="#8b8080"
                              style="fill:rgb(251, 251, 252);" />
                        <text x="0"
                              [attr.y]="-15 * scale.scaleY"
                              [attr.font-size]="11 * scale.scaleY"
                              text-anchor="middle"
                              fill="#8b8080">{{globalRes.last}}: {{segmentDistance(i)}}</text>
                        <text x="0"
                              [attr.y]="-30 * scale.scaleY"
                              [attr.font-size]="11 * scale.scaleY"
                              class="total-distance"
                              text-anchor="middle"
                              fill="#8b8080">{{totalDistance}}</text>
                  </ng-container>
            </g>
            <circle class="end-point"
                  [attr.stroke-width]="3 * scale.scaleX"
                  [attr.cx]="point.posX"
                  [attr.cy]="point.posY"
                  [attr.r]="4 * scale.scaleX" />
      </ng-container>
</svg:g>