import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FloorPlanModel
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';

@Component({
    selector: 'area-mirror-element',
    templateUrl: 'areaMirrorElement.component.html',
    styleUrls: ['./areaMirrorElement.component.scss'],
    host: {
        class: 'area-mirror-element',
    }
})
export class AreaMirrorElementComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    constructor(
        private planModel: FloorPlanModel,
        private element: ElementRef
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.planModel.floorPlanAreasLayerModel.drag.init(this.element);
    }

}
