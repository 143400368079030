<div class="content app-border map-tooltip"
     (wheel)="mouseWheel($event)"
     [class.pending]="isPending"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
          (click)="close()"
          class="action-block close fa-icon"></fa-icon>
     <div class="row position">
          <span class="element-name">{{typeArea}}</span>
          <span class="tooltip-label">
               x:
          </span>
          <span class="value">
               {{data.centerX}}
          </span>
          <span class="tooltip-label">
               y:
          </span>
          <span class="value">
               {{data.centerY}}
          </span>
     </div>
     <div class="row name">
          <span class="tooltip-label">
               {{res.name}}
          </span>
          <span class="value text-overflow"
               title="{{data.name}}">
               {{data.name}}
          </span>
     </div>
     <div class="row status">
          <span class="tooltip-label">
               {{res.status}}
          </span>
          <span class="value text-overflow"
               [class.low]="model.status===ocupancyStatus.low || model.status===ocupancyStatus.veryLow"
               [class.average]="model.status===ocupancyStatus.average"
               [class.high]="model.status===ocupancyStatus.high || model.status===ocupancyStatus.veryHigh"
               title="{{statusName}}">
               {{statusName}}
          </span>
     </div>
     <div class="row">
          <div class="chart-area">
               <div id="areaOccupancyChart"
                    [@fadeInContentByOpacityForHidden]="!model.isDataAvailable ? 'hidden':'visible'"
                    class="chart-block"></div>
               <div class="axis-horizontal-interval-titles"
                    *ngIf="model.isDataAvailable">
                    <div class="start-title">{{model.startDate | date : 'MMM dd'}}</div>
                    <div class="end-title">{{model.endDate | date : 'MMM dd'}}</div>
               </div>
               <div class="no-data"
                    @fadeInContentByOpacity
                    *ngIf="!model.isDataAvailable">
                    <div>
                         {{globalRes.noDataView}}
                    </div>
               </div>
          </div>
     </div>
     <div class="tags"
          *ngIf="model.tags?.length">
          <div class="sub-title">
               {{res.tags}}
          </div>
          <perfect-scrollbar class="scrollable-area">
               <div class="row tag-collection">
                    <div class="tag"
                         *ngFor="let item of model.tags">
                         <div class="name">{{item.name}}</div>
                    </div>
               </div>
          </perfect-scrollbar>
     </div>
</div>