<svg:g
    [attr.transform]="'translate(' + (object.posX - object.widthInPx/2) +',' + (object.posY - object.heightInPx/2) + ')'">
    <foreignObject x="0"
        [attr.width]="object.widthInPx"
        [attr.height]="object.heightInPx"
        y="0">
        <div class="image-wrapper">
            <img class="image"
                src="/assets/images/detectionChair.svg"
                (load)="onImageLoad()" />
        </div>
    </foreignObject>
</svg:g>