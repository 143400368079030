import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AreaPlanModel,
    FloorPlanModel
} from '@app/core';
import {
    SubscriptionNotifier
} from '@app/shared';
import { FloorPlanRes } from '@app/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'people-count-map',
    templateUrl: 'peopleCountMap.component.html',
    styleUrls: ['./peopleCountMap.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'people-count-map'
    }
})
export class PeopleCountMapComponent implements OnDestroy, OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() item: AreaPlanModel;

    @HostListener('click', ['$event'])
    componentClick(event: MouseEvent) {
        this.openTooltip(event);
    }

    public get peopleCount(): number {
        return this.item.peopleCount || 0;
    }

    constructor(
        public planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
    }

    private openTooltip(event: MouseEvent): void {
        this.planModel.floorPlanAreasLayerModel.setTooltipData(event.target as HTMLElement, this.item);
        this.planModel
            .onPlanDetectChanges
            .emit();
    }


}
