import { Injectable } from '@angular/core';
import {
    FloorPlanRes,
    GlobalRes
} from '@app/core';
import {
    PopupOptions,
    PopupsDialogService
} from '@app/shared';
import {
    Observable,
    of
} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PlanNotificationService {

    private pNotificationIsVisible: boolean;

    constructor(
        private res: FloorPlanRes,
        private globalRes: GlobalRes,
        private popupsDialogService: PopupsDialogService,
    ) {

    }

    public showXYLocationsConfirm(): Observable<boolean> {
        if (!this.pNotificationIsVisible) {            
            this.pNotificationIsVisible = true;
            const popupOptions = new PopupOptions();
            popupOptions.confirmButtonText = this.res.enableNow;
            popupOptions.rejectButtonText = this.globalRes.ok;
            popupOptions.isWordBreak = true;
            popupOptions.maxWidth = '560px';
            popupOptions.dialogClass = 'xy-locations-notification';
            return this.popupsDialogService
                .openConfirm(this.res.locationsDisabled, this.res.locationsDisabledMessage, popupOptions)
                .afterClosed()
                .pipe(map((t) => {
                    this.pNotificationIsVisible = false;
                    return t;
                }))
        } else {
            of([]);
        }
    }

}
