import {
    Component,
    OnChanges,
    ViewChild,
    HostListener,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit
} from '@angular/core';
import {
    ElementRef,
    OnDestroy
} from '@angular/core';
import {
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN
} from '@appConstants';
import { SubscriptionNotifier } from '@appModels';
import {
    DevicePlanModel,
    FloorPlanModel,
    FloorPlanOptionsModel
} from '@app/core';
import * as h337 from 'heatmap.js';
import { OccupancyTypeEnum } from '@app/core/analytics/enums';

@Component({
    selector: 'plan-core',
    templateUrl: 'planCore.component.html',
    styleUrls: ['./planCore.component.scss'],
    host: {
        class: 'plan-core',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN
    ]
})

export class PlanCoreComponent implements OnChanges, OnDestroy, OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @ViewChild('canvasPlan') canvasPlan: ElementRef;
    @ViewChild('canvasBitmapLayer') canvasBitmapLayer: ElementRef;
    @ViewChild('imagePlan') imagePlan: ElementRef;
    @ViewChild('peopleIcon') peopleIcon: ElementRef;
    @ViewChild('heatMap') heatMap: ElementRef;
    @ViewChild('svg', { static: true }) svgContainer: ElementRef;

    public isVisible: boolean;

    @HostListener('mousemove', ['$event'])
    public onMouseMove(event: MouseEvent) {
        this.planModel.floorEvents.onPlanMouseMove.emit(event);
    }

    @HostListener('mouseup', ['$event'])
    public onMouseUp(event: MouseEvent) {
        this.planModel.floorEvents.onPlanMouseUp.emit(event);
    }

    public onClick(event: MouseEvent) {
        this.planModel.floorEvents.onPlanClick.emit(event);
    }

    public contextMenu(event: MouseEvent) {
        event.preventDefault();
        this.planModel.contextMenu.open(event);
    }

    public occupancyType = OccupancyTypeEnum;

    public get viewBoxParse(): string {
        return !this.planModel.planViewBox ? null : `${this.planModel.planViewBox.x || 0},
        ${this.planModel.planViewBox.y || 0},${this.planModel.planViewBox.width || 0},${this.planModel.planViewBox.height || 0}`;
    }

    constructor(
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        public planModel: FloorPlanModel
    ) {
    }

    public ngOnInit(): void {
        const ro = new ResizeObserver((entries, observer) => {
            this.planModel.onResize.emit();
            this.changeDetectorRef.detectChanges();
        });
        ro.observe(this.elementRef.nativeElement);
        this.planModel
            .onRender
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onZoom
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onClear
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onChangePlanAccuracy
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onPlanDetectChanges
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onChangePlanAccuracy
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        (this.planModel
            .options as FloorPlanOptionsModel)
            .form
            .valueChanges
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });

        this.planModel
            .onPlanDetectChanges
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnChanges(): void {
    }

    public onImageLoad(event: Event): void {
        const canvasBitMap = this.canvasBitmapLayer.nativeElement;
        const canvas = this.canvasPlan.nativeElement;
        const el = (this.elementRef.nativeElement as HTMLElement);
        this.planModel.init(el,
            this.svgContainer.nativeElement,
            canvas,
            canvasBitMap,
            this.imagePlan.nativeElement,
            this.peopleIcon.nativeElement);
        setTimeout(() => {
            this.planModel.renderFloor();
            this.isVisible = true;
            this.changeDetectorRef.detectChanges();
        });
    }

    public isDetectionAreasVisible(device: DevicePlanModel): boolean {
        return (this.planModel.options.isDetectionAreasVisible || device.id === this.planModel.selectedObject?.id) && device.hasDetectionAreaPoints
    }
}
