<div class="content app-border map-tooltip"
     [class.without-env-data]='!model.isEnvDataAvailable'
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
          (click)="close()"
          class="action-block close fa-icon"></fa-icon>
     <div class="row element-name">
          <span class="tooltip-label">{{res.sensor}}</span>
          <a class="action-block"
               title="{{res.switchToDeviceManager}}"
               [routerLink]="deviceManagerPath"
               [state]="deviceManagerStateForPath">
               <fa-icon [icon]="{prefix: 'fac', iconName: 'navigate-to-device-manager'}"
                    class="fa-icon navigate-to-device-manager">
               </fa-icon>
          </a>
     </div>
     <div class="row">
          <span class="tooltip-label">
               {{res.name}}
          </span>
          <span class="value text-overflow"
               title="{{sensorTooltipData.name}}">
               {{sensorTooltipData.name}}
          </span>
     </div>
     <div class="row">
          <span class="tooltip-label">
               {{res.status}}
          </span>
          <span class="value text-overflow"
               title="{{linked}}">
               {{linked}}
          </span>
     </div>
     <div class="row position">
          <span class="tooltip-label">
               {{res.position}}
          </span>
          <span class="label-x">
               x:
          </span>
          <span class="value x">
               {{sensorTooltipData?.position?.posX | number: '1.0-2'}}
          </span>
          <span class="label-y">
               y:
          </span>
          <span class="value y">
               {{sensorTooltipData?.position?.posY | number: '1.0-2'}}
          </span>
     </div>
     <div class="row orientation">
          <span class="tooltip-label">
               {{res.orientation}}
          </span>
          <div class="tooltip-icon">
               <fa-icon [style.transform]="'rotate(' + (180 - sensorTooltipData.rotation) + 'deg)'"
                    [icon]="{prefix: 'fas',  iconName: 'long-arrow-alt-down'}"
                    class="fa-icon long-arrow-alt-down"></fa-icon>
          </div>
     </div>
     <div class="row">
          <span class="tooltip-label">
               {{res.lastTransmission}}
          </span>
          <span class="value text-overflow"
               title="{{sensorTooltipData.lastTransmission}}">
               {{(sensorTooltipData.lastTransmission | date : 'd MMM, y h:mm a') || '--'}}
          </span>
     </div>
     <ng-container *ngIf="model.isEnvDataAvailable">
          <div class="row line">
          </div>
          <div class="row sensors">
               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'temperature'}"
                         class="fa-icon temperature"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{temperatureC | doubledash}}">
                    {{temperatureC | doubledash}}
                    <span *ngIf="temperatureC">&#176;C</span>
               </span>
               <span class="value text-overflow"
                    title="{{temperatureF | doubledash}}">
                    {{temperatureF | doubledash}}
                    <span *ngIf="temperatureF">&#8457;</span>
               </span>

               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'pressure'}"
                         class="fa-icon pressure"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{pressure | doubledash}}">
                    {{pressure | doubledash}}
               </span>

               <div class="tooltip-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'humidity'}"
                         class="fa-icon humidity"></fa-icon>
               </div>
               <span class="value text-overflow"
                    title="{{humidity | doubledash}}">
                    {{humidity | doubledash}}
               </span>

          </div>
          <div class="row">
               <span class="tooltip-label">
                    {{res.lastReported}}
               </span>
               <span class="value text-overflow"
                    title="{{lastReported}}">
                    {{lastReported | doubledash}}
               </span>
          </div>
     </ng-container>
</div>