import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FloorPlanRes,
    AreaPlanModel,
    FloorPlanModel,
    GlobalRes,
    MapEntityTypeEnum,
    FLOOR_PLAN_STORAGE_TOKEN,
    IFloorPlanStorage,
    DeskStatsModel
} from '@app/core';
import {
    ErrorHandlerService,
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN,
    isEmpty,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { ElementRef } from '@angular/core';
import { BaseTooltipComponent } from '../baseTooltip';

@Component({
    selector: 'desk-stats-tooltip',
    templateUrl: 'deskStatsTooltip.component.html',
    styleUrls: ['./deskStatsTooltip.component.scss'],
    host: {
        class: 'desk-stats-tooltip',
    },
    providers: [
        DeskStatsModel
    ],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN]
})
export class DeskStatsTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public isPending: boolean;

    @Input() planModel: FloorPlanModel;

    public get typeAreaIcon(): string {
        return 'work-desk';
    }

    public get data(): AreaPlanModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaPlanModel;
    }

    public get totalUseTimeInHours(): string {
        return this.areaStatsModel.totalUseTimeInHours ? this.areaStatsModel.totalUseTimeInHours + 'h' : '';
    }

    public get averageUtilizationInPercent(): string {
        return this.averageUtilizationInPercentAvailable ? (this.areaStatsModel.averageUtilizationInPercent / 100).toString() : '';

    }

    public get averageUtilizationInPercentAvailable(): boolean {
        return !isEmpty(this.areaStatsModel.averageUtilizationInPercent);

    }

    public get peakDeskTime(): string {
        return !isEmpty(this.areaStatsModel.peakDeskTime) ? this.areaStatsModel.peakDeskTime + this.globalRes.hourShort : null;
    }

    public get avgDeskTime(): string {
        return !isEmpty(this.areaStatsModel.avgDeskTime) ? this.areaStatsModel.avgDeskTime + this.globalRes.hourShort : null;
    }

    public get busiestDay(): string {
        switch (this.areaStatsModel.busiestDay) {
            case 1:
                return this.globalRes.monday;
            case 2:
                return this.globalRes.tuesday;
            case 3:
                return this.globalRes.wednesday;
            case 4:
                return this.globalRes.thursday;
            case 5:
                return this.globalRes.friday;
            case 6:
                return this.globalRes.saturday;
            case 7:
                return this.globalRes.sunday;
            default:
                return null;
        }
    }

    public get typeArea(): string {
        return this.res.desk;
    }

    public get sessionDurations2InPercent(): string {
        return isEmpty(this.areaStatsModel.sessionDurations2InPercent) ? '--' : (this.areaStatsModel.sessionDurations2InPercent + '%');
    }

    public get sessionDurations4InPercent(): string {
        return isEmpty(this.areaStatsModel.sessionDurations4InPercent) ? '--' : (this.areaStatsModel.sessionDurations4InPercent + '%');
    }

    public get sessionDurations6InPercent(): string {
        return isEmpty(this.areaStatsModel.sessionDurations6InPercent) ? '--' : (this.areaStatsModel.sessionDurations6InPercent + '%');
    }

    public get sessionDurations8InPercent(): string {
        return isEmpty(this.areaStatsModel.sessionDurations8InPercent) ? '--' : (this.areaStatsModel.sessionDurations8InPercent + '%');
    }

    public get availableSessionDurationInPercent(): string {
        return isEmpty(this.areaStatsModel.availableSessionDurationInPercent) ? '--' : (this.areaStatsModel.availableSessionDurationInPercent + '%');
    }

    public get sessionDurationsAvailable(): boolean {
        return this.data.type.toLowerCase() === MapEntityTypeEnum.desk.toLowerCase();
    }

    constructor(
        elementRef: ElementRef,
        res: FloorPlanRes,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(FLOOR_PLAN_STORAGE_TOKEN) private readonly floorPlanStorage: IFloorPlanStorage,
        private popupsDialogService: PopupsDialogService,
        private errorHandler: ErrorHandlerService,
        public globalRes: GlobalRes,
        public areaStatsModel: DeskStatsModel,
    ) {
        super(elementRef, res);
        this.isPending = true;
    }

    public ngOnInit(): void {
        this.onInit();
        this.initComponent();
        this.areaStatsModel.setOccupancyStatus(this.data.peopleCount);
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    protected getTooltipData(): AreaPlanModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaPlanModel;
    }

    public mouseWheel(event: WheelEvent): void {
        event.stopPropagation();
    }

    private initComponent(): void {
        this.loadData();
        this.planModel
            .floorPlanAreasLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    private loadData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector, 0);
        this.areaStatsModel
            .load(this.data.id, this.floorPlanStorage.getTimezone())
            .then(() => {
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.isPending = false;
                this.changeDetectorRef.detectChanges();
            })
            .catch((error) => {
                this.isPending = false;
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

}
