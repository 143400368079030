import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ChangeDetectorRef,
    HostListener
} from '@angular/core';
import {
    AreaTrafficLineModel,
    DevicePlanModel,
    FloorPlanModel
} from '@app/core';
import { FloorPlanRes } from '@app/core';
import {
    SubscriptionNotifier
} from '@app/shared';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: '[analytics-traffic-line-map]',
    templateUrl: 'analyticsTrafficLine.component.html',
    styleUrls: ['./analyticsTrafficLine.component.scss'],
    host: {
        class: 'analytics-traffic-line-map'
    }
})
export class AnalyticsTrafficLineComponent implements OnDestroy, OnInit, AfterViewInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    
    @HostListener('mousedown', ['$event'])
    componentMouseDown(event: MouseEvent) {
        this.mouseDown(event);
    }

    @HostListener('click', ['$event'])
    componentClick(event: MouseEvent) {
        this.openTooltip(event);
    }

    @Input() model: AreaTrafficLineModel;

    public get trafficAreaPoints(): string {
        return this.model.trafficAreaPoints;
    }

    public get trafficAreaDepthPoints(): string {
        return this.model.trafficAreaDepthPoints;
    }

    public get trafficArrowInPoints(): string {
        return this.model.trafficArrowInPoints;
    }

    public get trafficArrowOutPoints(): string {
        return this.model.trafficArrowOutPoints;
    }

    public get transformArea(): string {
        return this.model.transformArea;
    }

    public get borderPoints(): string {
        return this.model.borderPoints;
    }

    public get isValid(): boolean {
        return this.model.isReporting;
    }

    private pSelectedAsRelative: boolean;
    public get selectedAsRelative(): boolean {
        return this.pSelectedAsRelative;
    }

    public get selected(): boolean {
        return this.model.id === this.planModel.selectedObject?.id;
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.selectAsRelative();
        this.changeDetectorRef.detectChanges();
    }

    private selectAsRelative(): void {
        this.pSelectedAsRelative = (this.planModel.selectedObject as DevicePlanModel)?.linkedAreas?.some(t => t === this.model.id);
    }

    private mouseDown(e: MouseEvent): void {
        this.selectArea(e);
    }

    private selectArea(event: MouseEvent): void {
        event.stopPropagation();
        this.planModel.floorPlanAreasLayerModel.selectArea(this.model.toContract());
    }

    private openTooltip(event: MouseEvent): void {
        this.planModel.floorPlanAreasLayerModel.setTooltipData(event.target as HTMLElement, this.model);
        this.planModel
            .onPlanDetectChanges
            .emit();
    }
}
