import {
    ChangeDetectorRef,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FADE_IN_CONTENT_BY_OPACITY,
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN
} from '@appConstants';
import {
    Component,
    Input
} from '@angular/core';
import {
    FloorPlanRes,
    FloorPlanModel,
    GlobalRes,
    AreaTrafficLineModel,
    IFloorPlanStorage,
    FLOOR_PLAN_STORAGE_TOKEN,
    TrafficLineStatsModel
} from '@app/core';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { BaseTooltipComponent } from '../baseTooltip';
import {
    OccupancyStatusEnum
} from '@app/core/analytics/enums';

@Component({
    selector: 'tuning-area-traffic-line-tooltip',
    templateUrl: 'tuningAreaTrafficLineTooltip.component.html',
    styleUrls: ['./tuningAreaTrafficLineTooltip.component.scss'],
    host: {
        class: 'tuning-area-traffic-line-tooltip',
    },
    providers: [
        TrafficLineStatsModel
    ],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY,
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN],
})
export class TuningAreaTrafficLineTooltipComponent extends BaseTooltipComponent implements OnInit, OnDestroy {

    private baseFontSize = 1.154;
    private baseLength = 2;
    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    private get areaTrafficLine(): AreaTrafficLineModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaTrafficLineModel;
    }

    public ocupancyStatus = OccupancyStatusEnum;

    @Input() planModel: FloorPlanModel;

    public get fontSizeIn(): string {
        return this.fontSize(this.trafficLineStatsModel.peopleInNumber || 0);
    }

    public get fontSizeOut(): string {
        return this.fontSize(this.trafficLineStatsModel.peopleOutNumber || 0);
    }

    constructor(
        elementRef: ElementRef,
        private popupsDialogService: PopupsDialogService,
        private errorHandler: ErrorHandlerService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(FLOOR_PLAN_STORAGE_TOKEN) private readonly floorPlanStorage: IFloorPlanStorage,
        public res: FloorPlanRes,
        public globalRes: GlobalRes,
        public trafficLineStatsModel: TrafficLineStatsModel
    ) {
        super(elementRef, res);
    }

    public ngOnInit(): void {
        this.onInit();
        this.loadData();
        this.planModel
            .floorPlanAreasLayerModel
            .onTooltipDataChanged
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.loadData();
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    protected getTooltipData(): AreaTrafficLineModel {
        return this.planModel.floorPlanAreasLayerModel.tooltipData as AreaTrafficLineModel;
    }

    private loadData(): void {
        this.popupsDialogService.startRelativeLoading(this.blockSelector);
        this.trafficLineStatsModel
            .load(this.areaTrafficLine.id, this.floorPlanStorage.getTimezone())
            .then(() => {
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.changeDetectorRef.detectChanges();
            })
            .catch((error) => {
                this.popupsDialogService.breackRelativeLoading(this.blockSelector);
                this.errorHandler.handleError(error);
            });
    }

    private fontSize(value: number): string {
        return (this.baseFontSize - Math.max(value.toString().length - this.baseLength, 0) * 0.3) + 'em';
    }

}
