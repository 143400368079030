import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input
} from '@angular/core';
import {
    AreaPlanModel,
    DevicePlanModel,
    FloorPlanModel
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';

@Component({
    selector: '[analytics-area]',
    templateUrl: 'analyticsArea.component.html',
    styleUrls: ['./analyticsArea.component.scss'],
    host: {
        class: 'analytics-area'
    }
})
export class AnalyticsAreaComponent implements AfterViewInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() area: AreaPlanModel;

    @HostListener('mousedown', ['$event'])
    componentMouseDown(event: MouseEvent) {
        this.mouseDown(event);
    }

    public get transformArea(): string {
        return this.area.transformArea;
    }

    public get hasFavoriteTag(): boolean {
        return this.area?.hasFavoriteTag;
    }

    private pSelectedAsRelative: boolean;
    public get selectedAsRelative(): boolean {
        return this.pSelectedAsRelative;
    }

    public get selected(): boolean {
        return this.area.id === this.planModel.selectedObject?.id;
    }

    public get isPeopleCountVisible(): boolean {
        return this.planModel.options.isAreasPeopleCountVisible && !this.planModel.isSuspended;
    }

    public get isBookingStatusVisible(): boolean {
        return this.planModel.options.isBookingStatusVisible && this.area.hasBookingStatus;
    }

    public get areaOccupancy(): string {
        return this.isPeopleCountVisible ? this.area.areaOccupancy : '';
    }

    public get isValid(): boolean {
        return this.area.isReporting;
    }

    public get ralativeTransform(): string {
        return 'translate(-' + this.area.width * this.planModel.planViewBox.initializingScaleX / 2 + ', -' + this.area.length * this.planModel.planViewBox.initializingScaleY / 2 + ') scale(' + this.planModel.planViewBox.initializingScaleX / 26 + ',' + this.planModel.planViewBox.initializingScaleY / 26 + ')';
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public planModel: FloorPlanModel
    ) {

    }

    public ngAfterViewInit(): void {
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.selectAsRelative();
        this.changeDetectorRef.detectChanges();
    }

    private selectAsRelative(): void {
        this.pSelectedAsRelative = (this.planModel.selectedObject as DevicePlanModel)?.linkedAreas?.some(t => t === this.area.id);
    }

    private mouseDown(e: MouseEvent): void {
        this.selectArea(e);
    }

    private selectArea(event: MouseEvent): void {
        event.stopPropagation();
        this.planModel.floorPlanAreasLayerModel.selectArea(this.area.toContract());
    }

}
