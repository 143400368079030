<ng-container [ngSwitch]="object.type">
    <ng-container *ngSwitchCase="objectDetectionTypeEnum.table">
        <svg:g detection-table
            [object]="object">
        </svg:g>
    </ng-container>
    <!-- <ng-container *ngSwitchCase="objectDetectionTypeEnum.laptop">
        <svg:g detection-laptop
            [object]="object">
        </svg:g>
    </ng-container> -->
    <ng-container *ngSwitchCase="objectDetectionTypeEnum.chair">
        <svg:g detection-chair
            [object]="object">
        </svg:g>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
</ng-container>