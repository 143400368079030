<svg:g [attr.transform]="'translate(' + object.posX +',' + object.posY + ')'">
    <foreignObject x="0"
        [attr.width]="object.widthInPx"
        [attr.height]="object.heightInPx"
        y="0">
        <div class="image-wrapper">
            <img class="image"
                src="/assets/images/detectionLaptop.svg"
                (load)="onImageLoad()" />
        </div>
    </foreignObject>
</svg:g>