<div class="content app-border map-tooltip"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
          (click)="close()"
          class="action-block close fa-icon"></fa-icon>
     <div class="statistics">
          <div class="chart-area-total">
               <div class="chart-block">
                    <div class="total in">
                         <div class="label">{{res.totalIn}}</div>
                         <div class="value"
                              [style.font-size]="fontSizeIn">{{trafficLineStatsModel.peopleInNumber}}</div>
                    </div>
                    <div class="total out">
                         <div class="label">{{res.totalOut}}</div>
                         <div class="value"
                              [style.font-size]="fontSizeOut">{{trafficLineStatsModel.peopleOutNumber}}</div>
                    </div>
               </div>
          </div>
          <div class="chart-area">
               <div class="title">{{res.vlcTrendForLast30days}}</div>
               <div id="peopleTrafficTrendChart"
                    [@fadeInContentByOpacityForHidden]="!trafficLineStatsModel.isTrendsAvailable ? 'hidden':'visible'"
                    class="chart-block"></div>
               <div class="no-data"
                    @fadeInContentByOpacity
                    *ngIf="!trafficLineStatsModel.isTrendsAvailable && !isPending">
                    <div>
                         {{globalRes.noDataView}}
                    </div>
               </div>
          </div>
     </div>
     <div class="tags"
          *ngIf="trafficLineStatsModel.tags?.length">
          <div class="sub-title">
               {{res.tags}}
          </div>
          <perfect-scrollbar class="scrollable-area">
               <div class="tag-collection">
                    <div class="tag"
                         *ngFor="let item of trafficLineStatsModel.tags">
                         <div class="name">{{item.name}}</div>
                    </div>
               </div>
          </perfect-scrollbar>
     </div>
</div>