<div class="content app-border map-tooltip"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
          (click)="close()"
          class="action-block close fa-icon"></fa-icon>
     <div class="chart-area-total">
          <div class="chart-block">
               <div class="total in">
                    <div class="label">{{res.totalIn}}</div>
                    <div class="value"
                         [style.font-size]="fontSizeIn">{{trafficLineStatsModel.peopleInNumber}}</div>
               </div>
               <div class="total out">
                    <div class="label">{{res.totalOut}}</div>
                    <div class="value"
                         [style.font-size]="fontSizeOut">{{trafficLineStatsModel.peopleOutNumber}}</div>
               </div>
          </div>
     </div>
</div>