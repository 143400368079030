<div class="content app-border map-tooltip"
     (wheel)="mouseWheel($event)"
     [class.pending]="isPending"
     [style.top]="y + 'px'"
     [style.left]="x + 'px'">
     <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
          (click)="close()"
          class="action-block close fa-icon"></fa-icon>
     <div class="statistics">
          <div class="row name">
               {{data.name}}
          </div>
          <div class="row main-stat">
               <div class="cell left">
                    <div class="sub-row">
                         <fa-icon [icon]="{prefix: 'fac',  iconName: typeAreaIcon}"
                              class="fa-icon"
                              [ngClass]="typeAreaIcon"></fa-icon>
                         <span class="">{{typeArea}}</span>
                    </div>
                    <div class="sub-row peak-utilization">
                         <span class="tooltip-label">{{res.peakDeskTime}}</span>
                         <span>{{peakDeskTime | doubledash}}</span>
                    </div>
               </div>
               <div class="cell right">
                    <div class="sub-row position">
                         <span class="tooltip-label">
                              x:
                         </span>
                         <span class="value">
                              {{data.centerX}}
                         </span>
                         <span class="tooltip-label">
                              y:
                         </span>
                         <span class="value">
                              {{data.centerY}}
                         </span>
                    </div>
                    <div class="sub-row avg-utilization">
                         <span class="tooltip-label">{{res.avgDeskTime}}</span>
                         <span>{{avgDeskTime | doubledash}}</span>
                    </div>
               </div>
          </div>
          <div class="row time-used">
               <div class="sub-row">
                    <div class="cell total-use">
                         <span class="tooltip-label">{{res.timeUsed}}</span>
                         <div *ngIf="totalUseTimeInHours"
                              class="value">{{totalUseTimeInHours}}</div>
                    </div>
                    <div *ngIf="averageUtilizationInPercentAvailable"
                         class="cell average-utilization">{{averageUtilizationInPercent | percent : '1.0-0'}}</div>
                    <div *ngIf="!isPending && !averageUtilizationInPercentAvailable"
                         class="cell average-utilization">
                         <span class="no-data-view">{{globalRes.noData}}</span> %
                    </div>
               </div>
               <div class="sub-row time-used-bar">
                    <div class="time-used-bar-value"
                         [style.width]="areaStatsModel.averageUtilizationInPercent +'%'"></div>
               </div>
          </div>
          <div class="row insights">
               <div class="sub-row">
                    <div class="cell">
                         <div class="label">
                              <fa-icon [icon]="{prefix: 'fac',  iconName: 'calendar-light'}"
                                   class="fa-icon calendar-light"></fa-icon>
                              <span class="tooltip-label">{{res.busiestDay}}</span>
                         </div>
                         <span class="value">{{busiestDay | doubledash}}</span>
                    </div>
               </div>
          </div>
          <div class="row session-durations">
               <div class="sub-title">
                    {{res.sessionDurations}}
                    <div *ngIf="!areaStatsModel.hasSessionData"
                         class="no-data-view">
                         {{globalRes.noData}}
                    </div>
               </div>
               <div class="sub-row bar">
                    <div class="bar-value low"
                         [style.width]="100 * areaStatsModel.sessionDurations2InPercent/areaStatsModel.maxDurationSession + '%'">
                    </div>
                    <div class="bar-value average"
                         [style.width]="100 * areaStatsModel.sessionDurations4InPercent/areaStatsModel.maxDurationSession + '%'">
                    </div>
                    <div class="bar-value high"
                         [style.width]="100 * areaStatsModel.sessionDurations6InPercent/areaStatsModel.maxDurationSession + '%'">
                    </div>
                    <div class="bar-value very-high"
                         [style.width]="100 * areaStatsModel.sessionDurations8InPercent/areaStatsModel.maxDurationSession + '%'">
                    </div>
                    <div class="bar-value available"
                         [style.width]="100 * areaStatsModel.availableSessionDurationInPercent/areaStatsModel.maxDurationSession + '%'">
                    </div>
               </div>
               <div class="sub-row legends">
                    <div class="cell">
                         <div class="item">
                              <div class="icon low"></div>
                              <div class="tooltip-label">1-2h:</div>
                              <div class="value">{{sessionDurations2InPercent}}</div>
                         </div>
                         <div class="item">
                              <div class="icon average"></div>
                              <div class="tooltip-label">3-4h:</div>
                              <div class="value">{{sessionDurations4InPercent}}</div>
                         </div>
                    </div>
                    <div class="cell">
                         <div class="item">
                              <div class="icon high"></div>
                              <div class="tooltip-label">5-6h:</div>
                              <div class="value">{{sessionDurations6InPercent}}</div>
                         </div>
                         <div class="item">
                              <div class="icon very-high"></div>
                              <div class="tooltip-label">7-8h:</div>
                              <div class="value">{{sessionDurations8InPercent}}</div>
                         </div>
                    </div>
                    <div class="cell">
                         <div class="item">
                              <div class="icon available"></div>
                              <div class="tooltip-label">{{res.available}}</div>
                              <div class="value">{{availableSessionDurationInPercent}}</div>
                         </div>
                    </div>
               </div>
          </div>
          <div class="row">
               <span class="notice">{{res.areaTooltipDataNotice}}</span>
          </div>
     </div>
     <div class="tags"
          *ngIf="areaStatsModel.tags?.length">
          <div class="sub-title">
               {{res.tags}}
          </div>
          <perfect-scrollbar class="scrollable-area">
               <div class="row tag-collection">
                    <div class="tag"
                         *ngFor="let item of areaStatsModel.tags">
                         <div class="name">{{item.name}}</div>
                    </div>
               </div>
          </perfect-scrollbar>
     </div>
</div>