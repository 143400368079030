import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    ElementRef
} from '@angular/core';
import {
    AreaPlanModel,
    FloorPlanModel
} from '@app/core';
import { FloorPlanRes } from '@app/core';
import { BookingStatusEnum } from '@app/core/analytics/enums';

@Component({
    selector: 'booking-status',
    templateUrl: 'bookingStatus.component.html',
    styleUrls: ['./bookingStatus.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'booking-status'
    }
})
export class BookingStatusComponent implements OnInit {

    @Input() item: AreaPlanModel;

    public get bookingStatus(): string {
        switch (this.item?.bookingStatus) {
            case BookingStatusEnum.booked:
                return 'booked';
            case BookingStatusEnum.checkIn:
                return 'check-in';
            case BookingStatusEnum.vacant:
                return 'vacant';
            default:
                return '';
        }
    }

    constructor(
        private elementRef: ElementRef,
        public planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

    public ngOnInit(): void {

    }

}
