import {
    Component
} from '@angular/core';
import {
    FloorPlanModel
} from '@app/core';

@Component({
    selector: 'draggable-mirror-elements',
    templateUrl: 'draggableMirrorElements.component.html',
    styleUrls: ['./draggableMirrorElements.component.scss'],
    host: {
        class: 'draggable-mirror-elements',
    }
})
export class DraggableMirrorElementsComponent {

    public get isDeviceDrag(): boolean {
        return this.planModel.floorPlanDevicesLayerModel.drag.isDrag;
    }

    public get isAreaDrag(): boolean {
        return this.planModel.floorPlanAreasLayerModel.drag.isDrag;
    }

    public get targetName(): string {
        return this.planModel.floorPlanAreasLayerModel.drag.target;
    }

    constructor(
        private planModel: FloorPlanModel
    ) {

    }

}
