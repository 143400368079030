<svg:g *ngIf="isVisible">
    <ng-container *ngIf="isBaseArea && isAreasAnalyticsVisible">
        <g analytics-area
           [area]="area"></g>
    </ng-container>
    <ng-container *ngIf="isBaseArea && isAreasTuningVisible">
        <g tuning-area
           [area]="area"></g>
    </ng-container>
    <ng-container *ngIf="isTrafficLine && isTrafficLineAnalyticsVisible">
        <g analytics-traffic-line-map
           [model]="area"></g>
    </ng-container>
    <ng-container *ngIf="isTrafficLine && isTrafficLineTuningVisible">
        <g tuning-traffic-line-map
           [model]="area"></g>
    </ng-container>
</svg:g>