import {
    Component,
    Input
} from '@angular/core';
import {
    FloorPlanRes,
    FloorPlanModel,
    FloorPlanContextMenuModel
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';
import { FADE_IN_CONTENT_BY_OPACITY } from '@appConstants';
import {
    OnInit,
    OnDestroy
} from '@angular/core';

@Component({
    selector: 'plan-context-menu',
    templateUrl: 'planContextMenu.component.html',
    styleUrls: ['./planContextMenu.component.scss'],
    host: {
        class: 'plan-context-menu',
    },
    animations: [
        FADE_IN_CONTENT_BY_OPACITY
    ]
})
export class PlanContextMenuComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() positionMarker: SVGElement;

    public get menu(): FloorPlanContextMenuModel {
        return this.planModel?.contextMenu;
    }

    public get isMenuVisible(): boolean {
        return this.menu?.isVisible && this.planModel.options.isContextMenuAvailable;
    }

    public get x(): number {
        return this.menu?.x;
    }

    public get y(): number {
        return this.menu?.y;
    }

    constructor(
        private planModel: FloorPlanModel,
        public res: FloorPlanRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
        this.menu.destroy();
    }

    public ngOnInit(): void {
        this.menu.init(this.positionMarker);
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.menu.close();
            });
        this.planModel
            .onResize
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.menu.close();
            })
    }

    public measureDistance(event: MouseEvent): void {
        event.stopPropagation();
        this.menu.startMeasureDistance();
    }

    public deleteDistance(): void {
        this.menu.deleteMeasureDistance();
    }

    public addDevice(): void {
        this.menu.addDevice();
    }

    public addArea(): void {
        this.menu.addArea();
    }

}
