import {
    Component,
    HostListener
} from '@angular/core';
import {
    FloorPlanModel
} from '@app/core';

@Component({
    selector: 'plan-view',
    templateUrl: 'planView.component.html',
    styleUrls: ['./planView.component.scss'],
    host: {
        class: 'plan-view',
    }
})

export class PlanViewComponent {


    @HostListener('window:mouseup', ['$event'])
    globalMouseUp(event: MouseEvent) {
        this.planModel.panning.stopPan(event);
    }

    @HostListener('window:mousemove', ['$event'])
    globalMouseMove(event: MouseEvent) {
        this.planModel.panning.move(event);
    }

    constructor(
        public planModel: FloorPlanModel
    ) {
    }

    public mouseWheel(event: WheelEvent): void {
        event.stopPropagation();
        if (event.deltaY > 0) {
            this.planModel.zoomOut();
        } else {
            this.planModel.zoomIn();
        }
    }

    public mouseDown(e: MouseEvent): void {
        const tooltip = (e.target as HTMLElement).closest('.floor-tooltip');
        if (!tooltip) {
            this.planModel.panning.startPan(e);
        }
    }
}
