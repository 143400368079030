import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {
  DeviceAnalyticsTooltipComponent,
  MapTooltipComponent,
  AreaMapComponent,
  SensorMapSimpleComponent,
  PlanCoreComponent,
  AreaReceptionTooltipComponent,
  PeopleCountMapComponent,
  PlanViewComponent,
  AnalyticsTrafficLineComponent,
  AnalyticsAreaComponent,
  TuningAreaComponent,
  DraggableMirrorElementsComponent,
  DeviceMirrorElementComponent,
  AreaMirrorElementComponent,
  DeviceMasterTooltipComponent,
  SensorMapMasterComponent,
  TuningTrafficLineComponent,
  AnalyticsAreaTrafficLineTooltipComponent,
  TuningAreaTrafficLineTooltipComponent,
  DetectionAreaComponent,
  PlanContextMenuComponent,
  PlanRulerComponent,
  BookingStatusComponent,
  CommissioningObjectComponent,
  CommissioningTargetComponent,
  MeetingRoomStatsTooltipComponent,
  DeskStatsTooltipComponent,
  DetectionObjectComponent,
  DetectionLaptopComponent,
  DetectionTableComponent,
  DetectionChairComponent,
  PlanView3DComponent
} from './components';
import {
  FloorPlanRes,
  PlanViewProvider,
  PlanViewStateProvider
} from '@app/core';
import { PlanNotificationService } from './services';
import { RouterModule } from '@angular/router';
import { FabricService } from '@app/shared';

const COMPONENTS = [
  DetectionAreaComponent,
  DeviceAnalyticsTooltipComponent,
  MapTooltipComponent,
  AreaMapComponent,
  SensorMapSimpleComponent,
  PlanCoreComponent,
  AreaReceptionTooltipComponent,
  PeopleCountMapComponent,
  PlanViewComponent,
  AnalyticsTrafficLineComponent,
  AnalyticsAreaComponent,
  TuningAreaComponent,
  DraggableMirrorElementsComponent,
  DeviceMirrorElementComponent,
  AreaMirrorElementComponent,
  DeviceMasterTooltipComponent,
  SensorMapMasterComponent,
  TuningTrafficLineComponent,
  AnalyticsAreaTrafficLineTooltipComponent,
  PlanContextMenuComponent,
  PlanRulerComponent,
  BookingStatusComponent,
  TuningAreaTrafficLineTooltipComponent,
  PlanContextMenuComponent,
  PlanRulerComponent,
  TuningAreaTrafficLineTooltipComponent,
  CommissioningTargetComponent,
  CommissioningObjectComponent,
  MeetingRoomStatsTooltipComponent,
  DeskStatsTooltipComponent,
  DetectionObjectComponent,
  DetectionTableComponent,
  DetectionLaptopComponent,
  DetectionChairComponent,
  PlanView3DComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [COMPONENTS],
  providers: [
    FabricService,
    PlanViewProvider,
    PlanViewStateProvider,
    PlanNotificationService,
    FloorPlanRes
  ]
})
export class FloorPlanModule { }
