<div @fadeInContentByOpacity
     class="floor-tooltip"
     *ngIf="isTooltipVisible">
     <area-reception-tooltip *ngIf="isVisibleReceptionTooltip"
                             [planModel]="planModel"></area-reception-tooltip>
     <meeting-room-stats-tooltip *ngIf="isVisibleMeetingTooltip"
                           [planModel]="planModel"></meeting-room-stats-tooltip>
     <desk-stats-tooltip *ngIf="isVisibleDeskTooltip"
                        [planModel]="planModel"></desk-stats-tooltip>
     <analytics-area-traffic-line-tooltip *ngIf="isVisibleAnalyticsTraficLineTooltip"
                                          [planModel]="planModel"></analytics-area-traffic-line-tooltip>
     <tuning-area-traffic-line-tooltip *ngIf="isVisibleTuningsTraficLineTooltip"
                                          [planModel]="planModel"></tuning-area-traffic-line-tooltip>
     <device-analytics-tooltip *ngIf="isDeviceAnalyticsTooltipVisible"
                               [planModel]="planModel"></device-analytics-tooltip>
     <device-master-tooltip *ngIf="isDeviceMasterTooltipVisible"
                            [planModel]="planModel"></device-master-tooltip>
</div>