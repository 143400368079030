import {
    Component,
    Input
} from '@angular/core';
import {
    DetectionObjectPlanModel,
    ObjectDetectionTypeEnum
} from '@app/core';

@Component({
    selector: '[detection-object]',
    templateUrl: 'detectionObject.component.html',
    styleUrls: ['./detectionObject.component.scss'],
    host: {
        class: 'detection-object'
    }
})
export class DetectionObjectComponent  {

    @Input() object: DetectionObjectPlanModel;

    public readonly objectDetectionTypeEnum = ObjectDetectionTypeEnum;

    constructor(
    ) {

    }

}

